import {useLocation} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import {GeneralContext} from "../contexts/GeneralContext";
import ProgressCircle from "../components/ProgressCircle";
import useUserDetails from "../hooks/useUserDetails";
import useConditionalNavigation from "../hooks/navigation/useConditionalNavigation";

// If authenticated, don't allow sign up and login flow to be displayed
export default function UnauthenticatedRouteGuard({ children }: any) {
    const {checkAccountStatuses, setRepId} = useContext(GeneralContext);

    const conditionalNav = useConditionalNavigation();

    const location = useLocation(); // get location from useLocation hook

    const {user, loadingUser: loading, loadingUserDetails: loadingUserMeta, userDetails: usermeta} = useUserDetails();

    useEffect(() => {
        if (user && !loading && usermeta) {
            checkAccountStatuses(usermeta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usermeta])

    if (loading || loadingUserMeta) {
        return <ProgressCircle/>;
    }
    if (user && !loading && usermeta) {
        if (location) {
            const params = new URLSearchParams(location.search);
            setRepId(params.get('repId'));
        }

        localStorage.setItem('isAuthenticated', 'true');

        if (children.type.name === 'TraderAI') {
            return children;
        }

        if (conditionalNav.component) {
            return conditionalNav.component;
        }
    }

    localStorage.setItem('isAuthenticated', 'false');
    return children
};
