import { Navigate, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { firebase_auth, firebase_firestore, firebase_functions } from "../../config/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { GeneralContext } from "../contexts/GeneralContext";
import { signInWithCustomToken } from "firebase/auth";
import ProgressCircle from "../components/ProgressCircle";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useMarket } from "../contexts/MarketContext";
import { SnackbarHelperContext } from "../contexts/SnackbarHelperContext";
import { useExternalRedirect } from "../hooks/useExternalRedirect";
import useUserDetails from "../hooks/useUserDetails";
import useConditionalNavigation from "../hooks/navigation/useConditionalNavigation";

export default function GreenChartSubscriptionRouteGuard({ children }: any) {
    const [tokenLoading, setTokenLoading] = useState(false);
    const [allowedDomains, setAllowedDomains] = useState<string[]>([]);
    const [redirectLink, setRedirectLink] = useState<string | null>(null);

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { preserveUrlParams, checkAccountStatuses, setRepId, setTrialDays } = useContext(GeneralContext);
    const { setSnackbarSettings } = useContext(SnackbarHelperContext);

    const [validatingCoupon, setValidatingCoupon] = useState<boolean>(false);

    const { user, loadingUser: loading, userDetails: usermeta, loadingUserDetails: loadingUserMeta } = useUserDetails();

    const [validateCoupon] = useHttpsCallable(firebase_functions, 'validateCoupon');
    const { dispatch } = useMarket(); // Assuming GeneralContext provides dispatch

    const redirectToExternalSite = useExternalRedirect();

    const authToken = searchParams.get('authToken');
    const location = useLocation();

    // Authentication Token and not logged in
    if (!user && !loading && authToken && !tokenLoading) {
        signInWithCustomToken(firebase_auth, authToken).then(() => {
            setTokenLoading(false);
        });
        setTokenLoading(true);
    }

    useEffect(() => {
        Promise.all([
            getDocs(collection(firebase_firestore, "whitelistedAuthDomains")),
            getDocs(collection(firebase_firestore, "tradingHouses"))
        ]).then(([whitelistSnapshot, tradingHouseSnapshot]) => {
            const domains: string[] = [];
            whitelistSnapshot.forEach(doc => {
                domains.push(doc.data().domain);
            });
            tradingHouseSnapshot.forEach(doc => {
                const tradingHouse = doc.data();
                if (tradingHouse.domains) {
                    domains.push(...tradingHouse.domains);
                }
            });
            setAllowedDomains(domains);
        }).catch(error => {
            console.error("Error fetching domains:", error);
        });
    }, []);

    useEffect(() => {
        // Check for coupon code in the search parameters
        const couponCode = searchParams.get('coupon');
        if (couponCode) {
            setValidatingCoupon(true);

            validateCoupon({ coupon: couponCode })
                .then(result => {
                    setValidatingCoupon(false);
                    const data = result?.data as any;
                    if (data && data.validated) {
                        // Dispatch the validated coupon
                        dispatch({ type: 'SET_COUPON', payload: data.coupon });
                        setSnackbarSettings({ severity: 'success', message: "Coupon applied successfully!", autoHideDuration: 6000 });
                    } else {
                        setSnackbarSettings({ severity: 'error', message: "Coupon is not valid." });
                    }
                })
                .catch(error => {
                    console.error('Error validating the coupon.');
                });
        }

        const repId = searchParams.get('repId');
        if (repId) {
            setRepId(repId);
        }

        const trialDays = searchParams.get('td');
        if (trialDays) {
            const trialDaysParsed = parseInt(trialDays);

            if (isNaN(trialDaysParsed) || trialDaysParsed > 30 || trialDaysParsed < 1) {
                return;
            }

            setTrialDays(trialDaysParsed);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    useEffect(() => {
        const checkRedirectLink = () => {
            const link: string | null = searchParams.get('redirectTo');
            let linkDomain: string | null = null;

            if (link) {
                try {
                    const url = new URL(link);
                    linkDomain = url.protocol + '//' + url.hostname;
                } catch (_) {
                    // for non-urls, handle as a special case
                    redirectToExternalSite(link); // might want this to be a different hook
                }
            }

            if (link && link.length && linkDomain && allowedDomains.includes(linkDomain)) {
                setRedirectLink(link);
            } else {
                setRedirectLink(null);
            }
        };
        checkRedirectLink();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, allowedDomains]);

    useEffect(() => {
        if (usermeta) {
            checkAccountStatuses(usermeta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usermeta])

    useEffect(() => {
        if (searchParams.get('annual') === 'true') {
            dispatch({ type: 'SET_ANNUAL_SELECTED', payload: true});

            searchParams.delete('annual');
            // eslint-disable-next-line no-useless-concat
            navigate('.' + '?' + searchParams.toString(), { replace: true });
        }
    })

    // Use the conditional navigation hook
    const conditionalNav = useConditionalNavigation();

    if (location.pathname === '/dashboard') {
        if (conditionalNav.component) {
            return conditionalNav.component;
        }
    }

    console.log('GreenChartSubscriptionRouteGuard rendering', location.pathname);
    if ((tokenLoading || loading || loadingUserMeta || validatingCoupon) && (!usermeta && !user)) {
        return <ProgressCircle/>;
    }

    if (authToken) {
        searchParams.delete("authToken");
        setSearchParams(searchParams)
        return;
    }

    if (!user) {
        return <Navigate to={`/login${preserveUrlParams(searchParams)}`}/>;
    }

    if (redirectLink) {
        redirectToExternalSite(redirectLink);
        return null; // Optionally, return a loading indicator
    }

    return children;
};
